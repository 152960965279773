import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'web-chat-functions';

  platform;
  link = "";
  storeLink = "";
  href = window.location.href;
  url
  key;
  session_id;
  goToLink = 'Go to tester Desktop';
  showStore = false;
  goToLinkStore = 'Open App In Store';


  constructor() {
    this.platform = this.getMobileOperatingSystem();
    this.url = new URL(this.href);
    this.key = this.url.searchParams.get("key");
    this.session_id = this.url.searchParams.get("session_id");

  }

  ngOnInit(): void {
    this.start();
  }


  start() {

    if (this.key != null) {
      if (this.platform == "Android") {
        //this.link = "https://panel.mediaprobe.com/tester/?key=" + encodeURIComponent(this.key)
        //version app
        this.link = 'https://mediaprobe.page.link/?link=https://panel.mediaprobe.com/tester/?key=' + encodeURIComponent(this.key) + '&apn=mindprober.studies.directview&amv=204';
        this.storeLink = "https://play.google.com/store/apps/details?id=mindprober.studies.directview";
      } else if (this.platform == "iOS") {
        //this.link = "https://" + "panel.mediaprobe.com/tester/?key=" + this.key;
        //isi + version app
        this.link = 'https://mediaprobe.page.link/?link=https://panel.mediaprobe.com/tester/?key=' + this.key + '&ibi=mindprober.studies.DirectView&isi=1540778460&imv=2.0.2';
        this.storeLink = "https://itunes.apple.com/app/id1540778460";
      } else {
        this.link = "https://tester.app.mediaprobe.com/tester/";
        this.storeLink = this.link;
      }
    } else {
      if (this.platform == "Android") {
        //this.link = "https://panel.mediaprobe.com/tester/?session_id=" + this.session_id
        this.link = 'https://mediaprobe.page.link/?link=https://panel.mediaprobe.com/tester/?session_id=' + this.session_id + '&apn=mindprober.studies.directview&amv=204';

        this.storeLink = "https://play.google.com/store/apps/details?id=mindprober.studies.directview";
      } else if (this.platform == "iOS") {
        //this.link = "https://" + "panel.mediaprobe.com/tester/?session_id=" + this.session_id;
        this.link = 'https://mediaprobe.page.link/?link=https://panel.mediaprobe.com/tester/?session_id=' + this.session_id + '&ibi=mindprober.studies.DirectView&isi=1540778460&imv=2.0.2';

        this.storeLink = "https://itunes.apple.com/app/id1540778460";
      } else {
        this.link = "https://tester.app.mediaprobe.com/tester/";
        this.storeLink = this.link;
      }
    }

    // (<HTMLElement>document.getElementById("link")).textContent = this.link;
    // (<HTMLElement>document.getElementById("link")).style.display = "none";

    if (this.platform == "iOS") {
      this.showStore = true;
      this.goToLink = 'Open App';
      //document.write("<h3>App link</h3>");
      document.write("<a onclick='copy();' href=" + this.link + ">Open App</a>");

      document.write("<a onclick='copy();' href=" + this.storeLink + ">Open App In Store</a>");
    } else if (this.platform == "Android") {
      this.goToLink = 'Open App';
      this.showStore = true;

      //document.write("<h3>Open app</h3>");
      document.write("<a onclick='copy();' href=" + this.link + ">Open App</a>");

      //document.write("<h3>App link</h3>");
      document.write("<a onclick='copy();' href=" + this.storeLink + ">Open App In Store</a>");
    } else {
      this.showStore = false;
      this.goToLink = 'Open Desktop Panel';

      //document.write("<h3>Open online panel</h3>");
      document.write("<a onclick='copy();' href=" + this.link + ">Open Desktop Panel</a>")
    }
  }



  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent; //|| navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) { //  && !window.MSStream
      return "iOS";
    }

    return "unknown";
  }


  copy() {
    /*(<HTMLInputElement>document.getElementById("copy_link")).select();
    document.execCommand("copy");
    navigator.clipboard.writeText('text to be copied');
    (<HTMLElement>document.getElementById("copy_link")).style.display = "block";

    (<HTMLElement>document.getElementById("copy_link")).select();
    (<HTMLElement>document.getElementById("copy_link")).setSelectionRange(0, 99999);
  
    navigator.clipboard.writeText(copyText.value);

    document.execCommand("copy");
    document.getElementById("copy_link").style.display = "none";*/
  }  
}